h2 {
  font-weight: var(--s-bold);
  font-size: var(--h2);
  line-height: 1.23;
  word-break: break-word;
  font-family: var(--font-secondary);
  color: var(--color-heading);
  margin: 0;
  margin-bottom: 20px;
}

p {
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
  font-weight: var(--p-regular);
  color: var(--color-body);
  margin: 0 0 40px;
}

h1 {
  font-weight: var(--s-bold);
  font-size: var(--h1);
  color: var(--color-heading);
  margin-bottom: 20px;
}