.footer-container {
  background: var(--background-color-1);
  padding: 20px;
}

.rn-footer-describe {
  font-size: 18px;
  margin-top: 20px;
  padding: 0 30px 0 0;
  color: var(--color-body);
  line-height: var(--line-height-b2);
  font-weight: var(--p-regular);
  color: var(--color-body);
  margin: 0 0 40px;
}

.widget-title {
  font-weight: var(--s-bold);
  line-height: 1.25;
  word-break: break-word;
  font-family: var(--font-secondary);
  color: var(--color-heading);
  font-size: 24px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-bottom: 25px;
}

.footer-list-one {
  margin: 0;
  list-style: none;
  padding: 0;
}

.single-list {
  margin: 11px 0;
  color: var(--color-body);
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
}

.footer-list-one .single-list a {
  font-size: 16px;
  color: var(--color-body);
  font-weight: 400;
  transition: var(--transition);
  position: relative;
}

ul li a {
  text-decoration: none;
  outline: none;
}

.footer-list-one .single-list a::after {
  position: absolute;
  content: "";
  left: auto;
  bottom: 0;
  background: var(--color-primary);
  width: 0;
  height: 2px;
  transition: 0.3s;
  right: 0;
}

.footer-list-one .single-list a:hover {
  color: var(--color-primary) !important;
}

.footer-list-one .single-list a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}

.copy-right-one {
  border-top: 1px solid var(--color-border);
  background: var(--background-color-1);
  padding: 20px 0 !important;
}
.copy-right-one .copyright-left {
  display: flex;
}

.copy-right-one .copyright-left .privacy {
  display: flex;
  list-style: none;
  margin: 0;
  padding-left: 18px;
  position: relative;
  margin-left: 30px;
}
.copy-right-one .copyright-left .privacy::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  width: 1px;
  height: 25px;
  background: var(--color-border);
  transform: translateY(-50%);
}
.copy-right-one .copyright-left .privacy li a {
  position: relative;
  color: var(--color-body);
  font-size: 16px;
  font-weight: 400;
}
ul li a {
  text-decoration: none;
}
.copy-right-one .copyright-left .privacy li a::after {
  position: absolute;
  content: "";
  left: auto;
  bottom: 0;
  background: var(--color-primary);
  width: 0;
  height: 2px;
  transition: 0.3s;
  right: 0;
}
.copy-right-one .copyright-left .privacy li + li::before {
    position: absolute;
    content: "";
    height: 3px;
    width: 3px;
    background: var(--color-body);
    left: 85px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
}
.copy-right-one .copyright-left .privacy li a::after {
  position: absolute;
  content: "";
  left: auto;
  bottom: 0;
  background: var(--color-primary);
  width: 0;
  height: 2px;
  transition: 0.3s;
  right: 0;
}
.copy-right-one .copyright-left .privacy li {
  margin: 0;
  padding: 0 12px;
}
ul li {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--color-body);
}