.header {
  background: transparent !important;
  color: rgba(0, 0, 0, 0.81) !important;
  box-shadow: none !important;
  border-bottom: 1px solid #ffffff14;
  backdrop-filter: blur(10px);
}

.title-text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #0F298B;
  white-space: nowrap;
  margin-left: 10px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 450px;
  margin-left: 20px;
}

.header-text {
  color: #acacac !important;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500 !important;
  font-weight: 500;
  padding: 28px 6px;
  font-size: 18px;
}

.header-text-mobile {
  color: #acacac !important;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500 !important;
  font-weight: 500;
  padding: 6px;
  font-size: 18px;
}

.hide-on-phone {
  display: block !important;
}

.hide-on-big-screen {
  display: none !important;
}
.logo-thumbnail::after {
  background: #ffffff14;
  width: 1px;
  height: 36px;
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 600px) {
  .text-align {
    text-align: center;
  }

  .column-reverse {
    flex-direction: column-reverse;
  }

  .hide-on-phone {
    display: none !important;
  }

  .hide-on-big-screen {
    display: block !important;
  }

  .herder-justify {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}