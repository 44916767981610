:root {
  --color-primary: #00a3ff;
  --color-primary-alta: #212e48;
  --color-gray: #f6f6f6;
  --color-gray-2: #f5f8fa;
  --color-subtitle: #f9004d;
  --background-color-1: #24243557;
  --background-color-2: #13131d;
  --background-color-3: #151521;
  --background-color-4: #242435;
  --gradient-one: linear-gradient(to right bottom, #2899d8, #00a3ff);
  --color-heading: #ffffff;
  --color-body: #acacac;
  --color-dark: rgb(29, 29, 29);
  --color-light-heading: #181c32;
  --color-light-body: #65676b;
  --color-border-white: #00000024;
  --color-midgray: #878787;
  --color-light: #E4E6EA;
  --color-lighter: #CED0D4;
  --color-lightest: #F0F2F5;
  --color-border: #ffffff14;
  --color-white: #ffffff;
  --color-white-75: rgba(255, 255, 255, 0.75);
  --color-success: #3EB75E;
  --color-danger: #FF0003;
  --color-warning: #FF8F3C;
  --color-info: #1BA2DB;
  --color-facebook: #3B5997;
  --color-twitter: #1BA1F2;
  --color-youtube: #ED4141;
  --color-linkedin: #0077B5;
  --color-pinterest: #E60022;
  --color-instagram: #C231A1;
  --color-vimeo: #00ADEF;
  --color-twitch: #6441A3;
  --color-discord: #7289da;
  --p-light: 300;
  --p-regular: 400;
  --p-medium: 500;
  --p-semi-bold: 600;
  --p-bold: 700;
  --p-extra-bold: 800;
  --p-black: 900;
  --s-light: 300;
  --s-regular: 400;
  --s-medium: 500;
  --s-semi-bold: 600;
  --s-bold: 700;
  --s-extra-bold: 800;
  --s-black: 900;
  --transition: 0.3s;
  --font-primary: "Roboto", sans-serif;
  --font-secondary: "Roboto", sans-serif;
  --font-size-b1: 14px;
  --font-size-b2: 18px;
  --font-size-b3: 22px;
  --line-height-b1: 1.50;
  --line-height-b2: 1.6;
  --line-height-b3: 1.7;
  --h1: 50px;
  --h2: 36px;
  --h3: 32px;
  --h4: 26px;
  --h5: 22px;
  --h6: 20px;
}
body {
  margin: 0;
  font-family: 'Roboto', -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  'Roboto',
  'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.50;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #acacac;
  font-weight: 400;
  position: relative;
  background: #13131d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::before {
  content: "";
  width: 100%;
  z-index: -1;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url(./images/noise.gif);
  background-color: rebeccapurple;
  opacity: 0.05;
}

.css-1abzdwk button {
  font-size: 13px !important;
  padding: 10px 20px;
}

.header-text:hover {
  color: #ddd !important;
}

.css-i6s8oy {
  padding-left: 0;
}

.MuiContainer-root.MuiContainer-maxWidthXl.css-vvb82q {
  margin-top: 60px;
}

.css-95g4uk h2 {
  font-size: 28px !important;
  color: #fff;
}

.MuiGrid-root.MuiGrid-container.css-1tz8m30 {
  margin-bottom: 50px;
}

.MuiContainer-root.css-vvb82q h2 {
  font-size: 29px !important;
  color: #fff;
  padding-left: 0 !important;
}

.MuiGrid-root.MuiGrid-container.css-1tz8m30 .slick-slider {
  margin-bottom: 50px !important;
}

.MuiContainer-root.MuiContainer-maxWidthXl.css-vvb82q .slick-slider {
  margin-bottom: 50px;
}

span.product-name {
  color: #fff;
}

span.latest-bid {
  color: #eee;
  font-weight: 300 !important;
  margin-top: 10px !important;
  display: block;
  font-size: 15px;
  word-spacing: 3px;
}

span.last-bid {
  font-size: 19px;
  color: #fff;
}

.css-u4p24i img {
  filter: brightness(0) invert(1);
  width: 22px;
}

.blog-card .product-name {
  color: #fff;
}

.css-1tz8m30>.MuiGrid-item p {
  color: #fff;
  font-weight: 300;
}

.MuiContainer-root {
  max-width: 1140px;
  width: 100%;
}

@media (min-width: 1400px) {
  .MuiContainer-root {
    max-width: 1440px;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .MuiContainer-root {
    max-width: 1140px;
    width: 100%;
  }
}

.MuiGrid-root.MuiGrid-container {
  /* margin-top: 47px; */
}

.filter-box {
  margin-top: 0;
}

.MuiContainer-root>h3.title {
  margin-bottom: 50px !important;
}

span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-9l3uo3 {
  color: #fff;
}

.css-zun73v {
  color: #fff;
}


@media (max-width: 767px) {
  .css-vubbuv {
    color: #fff;
  }

  .css-177ic5c {
    width: 100%;
    left: 0 !important;
    max-width: 100%;
    background: #181822;
    top: 48px !important;
  }

  .header-text {
    display: block !important;
    width: 100%;
    padding: 10px 10px;
    /* border-bottom: 1px solid #000; */
    color: #fff !important;
  }


  .css-177ic5c ul li:not(:last-child) {
    border-bottom: 1px solid #111;
  }

  .css-177ic5c ul li:first-child {
    border-top: 1px solid #111;
  }
}

.css-1abzdwk button {
  font-size: 13px !important;
  padding: 10px 20px;
}

.header-text:hover {
  color: #ddd !important;
}

.css-i6s8oy {
  padding-left: 0;
}

.MuiContainer-root.MuiContainer-maxWidthXl.css-vvb82q {
  margin-top: 60px;
}

.css-95g4uk h2 {
  font-size: 28px !important;
  color: #fff;
}

.MuiGrid-root.MuiGrid-container.css-1tz8m30 {
  margin-bottom: 50px;
}

.MuiContainer-root.css-vvb82q h2 {
  font-size: 29px !important;
  color: #fff;
  padding-left: 0 !important;
}

.MuiGrid-root.MuiGrid-container.css-1tz8m30 .slick-slider {
  margin-bottom: 50px !important;
}

.MuiContainer-root.MuiContainer-maxWidthXl.css-vvb82q .slick-slider {
  margin-bottom: 50px;
}

span.product-name {
  color: #fff;
}

span.latest-bid {
  color: #eee;
  font-weight: 300 !important;
  margin-top: 10px !important;
  display: block;
  font-size: 15px;
  word-spacing: 3px;
}

span.last-bid {
  font-size: 19px;
  color: #fff;
}

.css-u4p24i img {
  filter: brightness(0) invert(1);
  width: 22px;
}

.blog-card .product-name {
  color: #fff;
}

.css-1tz8m30>.MuiGrid-item p {
  color: #fff;
  font-weight: 300;
}

.MuiContainer-root {
  max-width: 1140px;
  width: 100%;
}

@media (min-width: 1400px) {
  .MuiContainer-root {
    max-width: 1440px;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .MuiContainer-root {
    max-width: 1140px;
    width: 100%;
  }
}

.MuiGrid-root.MuiGrid-container {
  /* margin-top: 47px; */
}

.filter-box {
  margin-top: 0;
}

.MuiContainer-root>h3.title {
  margin-bottom: 50px !important;
}

span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-9l3uo3 {
  color: #fff;
}

.css-zun73v {
  color: #fff;
}


@media (max-width: 767px) {
  .css-vubbuv {
    color: #fff;
  }

  .css-177ic5c {
    width: 100%;
    left: 0 !important;
    max-width: 100%;
    background: #181822;
    top: 48px !important;
  }

  .header-text {
    display: block !important;
    width: 100%;
    padding: 10px 10px;
    /* border-bottom: 1px solid #000; */
    color: #fff !important;
  }


  .css-177ic5c ul li:not(:last-child) {
    border-bottom: 1px solid #111;
  }

  .css-177ic5c ul li:first-child {
    border-top: 1px solid #111;
  }
}